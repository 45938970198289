* {
  margin: 0;
  padding: 0;
}
.newTree {
  margin: auto;
  width: 50%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
