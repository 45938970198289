* {
	margin: 0;
	padding: 0;
}
.Archive {
	width: 50%;
	height: 50vh;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}
