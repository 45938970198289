.Spacer {
	width: 90%;
	height: 15vh;
}
.Tree {
	width: 90%;
	height: 80vh;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.10070257611241218) 47%
	);
}
.leftSide {
	width: 30%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.374);
}
.nameOfTree {
	width: 100%;
	height: 10%;
	border-left: 1px solid rgba(255, 255, 255, 0.374);
	border-top: 1px solid rgba(255, 255, 255, 0.374);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: whitesmoke;
}
.details {
	width: 100%;
	height: 30%;
	border: 1px solid rgba(255, 255, 255, 0.374);
	border-right: none;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: whitesmoke;
}
.treePic {
	width: 100%;
	height: 50%;
	border: 1px solid rgba(255, 255, 255, 0.374);
	border-top: none;
	color: whitesmoke;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.buttonsContainer {
	width: 100%;
	height: 10%;
	border: 1px solid rgba(255, 255, 255, 0.374);
	border-top: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.treeButton {
	border: none;
	border-radius: 10px;
	padding: 4px;
	cursor: pointer;
}
.rightSide {
	width: 70%;
	height: 100%;
	border: 1px solid rgba(255, 255, 255, 0.374);
	border-left: none;
}
