.bonsaiImg {
	width: 50%;
	height: 50%;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.treeName {
	font-size: 1rem;
}
